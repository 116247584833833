import { FC } from 'react';
import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../Logo/Logo';
import Svg from '../Svg/Svg';
import './Footer.scss';

const Footer:FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <Svg />
            <footer className="footer">
            <div className="container">
                <div className="footer__inner">
                    <div className='footer__logo footer__logo--top'>
                        <Logo type='footer' />
                    </div>
                    <div className="footer__column">
                        <h4 className='footer__title'>{t("nav.info")}</h4>
                        <ul className="footer__list footer__list--nav">
                            <li className='footer__item'><NavLink className='footer__link' to='/'>{t("nav.about")}</NavLink></li>
                            <li className='footer__item'><NavLink className='footer__link' to='/home'>{t("nav.main")}</NavLink></li>
                            <li className='footer__item'><NavLink className='footer__link' to='/contacts'>{t("nav.contacts")}</NavLink></li>
                            <li className='footer__item'><NavLink className='footer__link' to='/feedback'>{t("nav.feedback")}</NavLink></li>
                        </ul>
                    </div>
                    <div className='footer__logo footer__logo--center'>
                        <Logo type='footer' />
                    </div>
                    <div className="footer__column">
                        <h4 className='footer__title'>{t("nav.contacts")}</h4>
                        <ul className="footer__list footer__list--contacts">
                            <li className='footer__item'>
                                <a className='footer__link footer__link--contact' href='tel:+380961989806'>
                                    <svg className='footer__icon'><use href='#phone'></use></svg>
                                    <span>+38 (096) 198-98-06</span>
                                </a>
                            </li>
                            <li className='footer__item'>
                                <a className='footer__link footer__link--contact' href='tel:+380960853934'>
                                    <svg className='footer__icon'><use href='#phone'></use></svg>
                                    <span>+38 (096) 085-39-34</span>
                                </a>
                            </li>
                            {/* <li className='footer__item'>
                                <a className='footer__link footer__link--contact' href='tel:+380686263226'>
                                    <svg className='footer__icon'><use href='#phone'></use></svg>
                                    <span>+38 (068) 626-32-26</span>
                                </a>
                            </li>
                            <li className='footer__item'>
                                <a className='footer__link footer__link--contact' href='tel:+380671990109'>
                                    <svg className='footer__icon'><use href='#phone'></use></svg>
                                    <span>+38 (067) 199-01-09</span>
                                </a>
                            </li> */}
                            <li className='footer__item'>
                                <a className='footer__link footer__link--contact' rel="noreferrer" href='mailto:vitamin.rsv@gmail.com' target='_blank'>
                                    <svg className='footer__icon'><use href='#mail'></use></svg>
                                    <span>vitamin.rsv@gmail.com</span>
                                </a>
                            </li>
                            <li className='footer__item'>
                                <span className='footer__link footer__link--contact'>
                                    <svg className='footer__icon'><use href='#location'></use></svg>
                                    <span>{t("order_page.our_address_short")}</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </footer>
        </>
    )
}

export default memo(Footer);