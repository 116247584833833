import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks/reduxHooks';

import Seo from '../../components/Seo/Seo';
import Categories from '../../components/Categories/Categories';
import './CatalogPage.scss';

const CatalogPage:FC = () => {
    const { t } = useTranslation();
    const currentLanguage = useAppSelector(state => state.languages.curentLang);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
    return (
        <>
            <Seo 
                title="Vitamin2015 «EVA» | Каталог" 
                description = 'Відкрийте для себе різноманіття смаків у нашому каталозі "Vitamin2015 Eva". Поглибіться в світ природних соків, зброжених соків, фруктових-овочевих сиропів та концентрованих чаїв. Вибирайте з нашого вишуканого асортименту, де кожен продукт – це гармонія смаку та користі для вашого здоров"я. Наші соки виробляються з найкращих інгредієнтів, щоб подарувати вам найсвіжіші та найсмачніші враження. Обирайте з упевненістю та насолоджуйтеся кожним ковзанням смаку разом з Vitamin2015 Eva.'
            />
            <section className="catalog-page">
            <div className="container">
                <div className="bread-crumbs">
                    <Link className='bread-crumbs__item' to='/home'>{t("nav.main")}</Link>
                    <span className='bread-crumbs__item'>{t("nav.categories")}</span>
                </div>
            </div>
            <Categories />
        </section>
        </>
    )
}

export default CatalogPage