import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IProductDetail, IPackage } from '../../types/types';
import { useAppSelector, useAppDispatch } from '../../hooks/reduxHooks';
import { onClickPlus, onClickMinus, addToCart, onQuantityChange } from '../../store/cartSlice';
import { unitChange } from '../../helpers/packageChange';
import mark from '../../assets/img/mark.svg';
import cart from '../../assets/img/cart1.png';
import './ProductOrderCard.scss';

interface ProductOrderCardProps {
    info: IProductDetail;
    setProductAdded: (added: boolean) => void;
    onCartOpen: () => Promise<void>;
}

const ProductOrderCard:FC<ProductOrderCardProps> = ({ info, setProductAdded, onCartOpen }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [productQuantity, setProductQuantity] = useState<number>(1);
    const [focus, setFocus] = useState<boolean>(false);
    const { id, title, packages, availability, images, barcode } = info;
    const promoPackage = packages.find(item => item.promo !== null);
    const cartItems = useAppSelector(state => state.cartItems.cartItems);
    const [currentSize, setCurrentSize] = useState<IPackage>(packages[0]);
    const packUnits = useAppSelector(state => state.packs.packUnit);

    const onCart = () => {
        if (info) {
            setProductAdded(true);
            dispatch(addToCart({
                id: id,
                title: title,
                images: images,
                price: currentSize.price,
                quanity: currentSize.min_count_buy,
                minQuanityOrder: currentSize.min_count_buy,
                promo: currentSize.promo,
                barcode: barcode,
                size: currentSize.id,
                sizeName: currentSize.container.name,
                unitId: currentSize.container.unit_id,
                packId: currentSize.container.type_id,
                capacity: currentSize.container.capacity
            }));
            window.setTimeout(() => setProductAdded(false), 2000);
        }
    }

    const quantityChnage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^[0-9]*$/;
        const value = e.target.value;

        if (regex.test(value)) {
            setProductQuantity(+value);
        }
    }

    const onPlus = (id: number) => {
        const size = currentSize.id;
        dispatch(onClickPlus({id, size}))
    }

    const onMinus = (id: number) => {
        const size = currentSize.id;
        dispatch(onClickMinus({id, size}));
    }

    const handleFocus = () => {
        setFocus(true);
    }

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (info && currentSize.min_count_buy && id) {
            const value = e.target.value.startsWith('0') ? e.target.value.slice(1) : e.target.value;
            if (productQuantity < currentSize.min_count_buy) {
                const minQuantity = currentSize.min_count_buy;
                dispatch(onQuantityChange({ id, value: minQuantity, size: currentSize.id }));
                setProductQuantity(minQuantity);
                setFocus(false);
            } else if (productQuantity > 9999) {
                const maxQuantity = 9999;
                dispatch(onQuantityChange({ id, value: maxQuantity, size: currentSize.id }));
                setProductQuantity(maxQuantity);
                setFocus(false);
            } else {
                dispatch(onQuantityChange({ id, value: +value, size: currentSize.id }));
                setProductQuantity(+value);
            }
        }
    }

    useEffect(() => {
        const itemQuanity = cartItems.find(item => item.id === id && item.size === currentSize.id);
        if (itemQuanity) {
            setProductQuantity(itemQuanity.quanity)
        }
    }, [id, cartItems, currentSize])

    return (
        <section className="product-order-card">
            <h1 className='product-order-card__title'>{title}</h1>
            {promoPackage && <p className="product-order-card__message">{t("buy_info.promo")}</p>}
            <div className='product-order-card__cart'>
                <div className='product-order-card__price'>
                    {currentSize.promo ? (
                        <p className='product-order-card__new-price'>
                            {t("buy_info.price") + ' '}  
                            <strong>{currentSize.promo.promo_price &&  Math.round(currentSize.promo.promo_price * 10) / 10} {t("buy_info.uah")} </strong>
                            <span>{currentSize.price} {t("buy_info.uah")}</span>
                        </p>
                    ) : <span>{t("buy_info.price")} {Math.round(currentSize.price * 10) / 10} {t("buy_info.uah")}</span>}
                </div>
                {
                    availability === true
                        ? <div className='product-order-card__availability product-order-card__availability--true'>{t("buy_info.in_stock")}</div>
                        : <div className='product-order-card__availability product-order-card__availability--false'>{t("buy_info.out_of_stock")}</div>
                }
                <h2 className='product-order-card__subtitle'>Розміри:</h2>
                <ul className='product-order-card__sizes'>
                    {packages.map(item => {
                            const isActive = item.id === currentSize.id;
                            const isPromo = item.promo;
                            const inCart = cartItems.find(cartItem => cartItem.id === id && cartItem.size === item.id);
                            return (
                                <li key={item.id} className='product-order-card__size-item'>
                                    {inCart && 
                                        <span className='product-order-card__in-cart'>
                                            <img src={cart} alt='Product in cart'/>
                                        </span>
                                    }
                                    <button
                                        title={item.container.name} 
                                        className={
                                        isActive 
                                            ? isPromo ? 'product-order-card__size product-order-card__size--promo product-order-card__size--promo--active' : 'product-order-card__size product-order-card__size--active'
                                            : isPromo ? 'product-order-card__size product-order-card__size--promo' : 'product-order-card__size'
                                        }
                                        onClick={() => setCurrentSize(item)}
                                        disabled={!item.available}
                                    >{item.container.capacity + ' ' + unitChange(item.container.unit_id, packUnits)}</button>
                                </li>
                            )
                        })}
                </ul>

                <p style={{'cursor': 'default'}} className='product-order-card__size-name'>{currentSize.container.name}</p>
                <p className='product-order-card__min-quanity'>{t("buy_info.min_count_buy")} {currentSize.min_count_buy}</p>

                {availability &&
                    cartItems.find(item => item.id === id && item.size === currentSize.id)
                    ? (
                        <>
                            <div className='product-order-card__quanity'>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <button className='product-order-card__btn-cart' onClick={() => onMinus(id)}>-</button>
                                    {/* <span className='product-order-card__num'>{productQuanuty}</span> */}
                                    <input
                                        className='product-order-card__input'
                                        onChange={quantityChnage}
                                        value={productQuantity}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                    />
                                    <button className='product-order-card__btn-cart' onClick={() => onPlus(id)}>+</button>
                                </div>
                                <p className='product-order-card__summary'>
                                    {t("buy_info.sum") + ' '}
                                    {
                                        currentSize.promo?.promo_price
                                            ? Math.round(productQuantity * currentSize.promo.promo_price * 10) / 10
                                            : Math.round(productQuantity * currentSize.price * 10) / 10
                                    } {t("buy_info.uah")}
                                </p>
                            </div>
                            <button onClick={onCartOpen} className='product-order-card__btn--cart--active'>
                                <img src={mark} alt='Додано в кошик' />
                                {t("buy_info.in_cart")}
                            </button>
                        </>
                    )
                    : <button onClick={onCart} className='product-order-card__btn--cart'>{t("buy_info.add_to_cart")}</button>
                }
            </div>
        </section>
    )
}

export default ProductOrderCard;