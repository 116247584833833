import { FC, useState, useEffect, useRef } from 'react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SliderThumbs.scss';

interface SliderThumbsProps {
    imgs: string[];
}

const SliderThumbs: FC<SliderThumbsProps> = ({ imgs }) => {
    const [nav1, setNav1] = useState<Slider | null>(null);
    const [nav2, setNav2] = useState<Slider | null>(null);
    const slider1Ref = useRef<Slider | null>(null);
    const slider2Ref = useRef<Slider | null>(null);
    const [activeSlide, setActiveSlide] = useState<number>(0);

    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        waitForAnimate: false,
        asNavFor: '.slider-nav',
        beforeChange: (current: number, next: number) => {
            setActiveSlide(next);
        },
    };

    const settingsThumbs = {
        slidesToShow: imgs.length >= 3 ? 3 : imgs.length,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        centerMode: true,
        swipeToSlide: true,
        focusOnSelect: true,
        waitForAnimate: false,
        centerPadding: '10px',
        arrows: false,
    };

    useEffect(() => {
        setNav1(slider1Ref.current);
        setNav2(slider2Ref.current);
    }, []);

    return (
        <div className="slider-wrapper">
            <Slider
                {...settingsMain}
                asNavFor={nav2 as Slider | undefined}
                ref={slider => (slider1Ref.current = slider)}
            >
                {imgs.map((slide, i) =>
                    <div className="slick-slide main" key={i}>
                        <img className="slick-slide-image" src={slide} />
                    </div>
                )}
            </Slider>
            <div className="thumbnail-slider-wrap">
                <Slider
                    {...settingsThumbs}
                    asNavFor={nav1 as Slider | undefined}
                    ref={slider => (slider2Ref.current = slider)}
                >
                    {imgs.map((slide, i) =>
                        <div
                            className={`slick-slide ${i === activeSlide ? 'active' : 'default'}`}
                            key={i}
                        >
                            <img
                                className="slick-slide-image slick-slide-image--thumbnails"
                                src={slide}
                            />
                        </div>
                    )}
                </Slider>
            </div>
        </div>
    )
}

export default SliderThumbs