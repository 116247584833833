import { FC, useState, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../hooks/reduxHooks';
import { IPackage } from '../../types/types';
import { useTranslation } from 'react-i18next';
import { unitChange } from '../../helpers/packageChange';
import './Product.scss';

interface ProductProps {
    id: number;
    title: string;
    img: string[];
    packages: IPackage[];
    type?: string;
}

const Product:FC<ProductProps> = ({ id, title, img, type, packages }) => {
    const { t } = useTranslation();
    const promoPackage = packages.find(item => item.promo !== null);
    const [currentSize, setCurrentSize] = useState<IPackage>(type === 'promo' && promoPackage ? promoPackage : packages[0]);
    const packUnits = useAppSelector(state => state.packs.packUnit);

    const onSize = (e: MouseEvent<HTMLButtonElement>, item: IPackage) => {
        e.preventDefault();
        setCurrentSize(item);
    }

    return (
        <Link 
            key={id}
            className={type ? `product product--${type}` : 'product'}
            to={`/product/${id}`}
            title={title}
        >
            <div className='product__top'>
                <div className={type ? `product__img-container product__img-container--${type}` : 'product__img-container'}>
                    {promoPackage && <p className="product__message">{t("buy_info.promo")}</p>}
                    <img className={`product__img product__img--${type && type}`} src={img && img[0]} alt={title} />
                </div>
                <h3 className='product__title'>{title && title.length > 32 ? title.slice(0, 32) + '...' : title}</h3>
                <ul className='product__sizes'>
                    {packages
                        .filter((item, i) => {
                            if(type === 'catalog') {
                                return i <= 2
                            } else {
                                return i <= 3
                            }
                        })
                        .map(item => {
                            const isActive = item.id === currentSize.id;
                            const isPromo = item.promo;
                            return (
                                <li key={item.id} className='product__size'>
                                    <button
                                        title={item.container.name}
                                        className={
                                        isActive 
                                            ? isPromo ? 'product__btn product__btn--promo product__btn--promo--active' : 'product__btn product__btn--active'
                                            : isPromo ? 'product__btn product__btn--promo' : 'product__btn'
                                        }
                                        onClick={(e) => onSize(e, item)}
                                        disabled={!item.available}
                                    >{item.container.capacity} <br className='product__br'/> { unitChange(item.container.unit_id, packUnits)}</button>
                                </li>
                            )
                        })}
                </ul>
                {type === 'catalog' 
                    ? packages.length > 3 && <p className='product__notification'>{`* ще ${packages.length - 3} розміри`}</p> 
                    : packages.length > 4 && <p className='product__notification'>{`* ще ${packages.length - 4} розміри`}</p> 
                }
            </div>
            <div className='product__bottom'>
                {currentSize.promo ? (
                    <div>
                        <p className='product__new-price'>
                            {currentSize.promo.promo_price &&  Math.round(currentSize.promo?.promo_price * 10) /10} {t("buy_info.uah")}
                            </p>
                        <p className='product__old-price'>{Math.round(currentSize.price * 10) / 10} {t("buy_info.uah")}</p>
                    </div>
                ) : (<p className={`product__price product__price--${type && type}`}>{Math.round(currentSize.price * 10) / 10} {t("buy_info.uah")}</p>)}
            </div>
        </Link>
    )
}

export default Product;