import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IBanner } from '../../types/types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';

import './Hero.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

interface HeroProps {
    banners: IBanner[];
}

const Hero:FC<HeroProps> = ({ banners }) => {
    const { t } = useTranslation();
    return (
        <section className="hero">
            <Swiper
                modules={[Autoplay, Pagination, Navigation]}
                slidesPerView={1}
                loop={true}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
            >
                {banners && banners.map((slide) => (
                    <SwiperSlide key={slide.image}>
                        {slide.link ? (
                            <Link to={slide.link}>
                                <div className="hero__slide" style={{backgroundImage: `url(${slide.image})`}}>
                                    <div className="container hero__container">
                                        <h1 className='hero__title'>{slide.title}</h1>
                                        {slide.description && <p className='hero__text'>{slide.description}</p>}
                                        {/* {slide.link && <Link className='hero__link' to={slide.link}>{t("proposition.detail")}</Link>} */}
                                    </div>
                                </div>
                            </Link>
                        ) : (
                            <div className="hero__slide" style={{backgroundImage: `url(${slide.image})`}}>
                                <div className="container hero__container">
                                    <h1 className='hero__title'>{slide.title}</h1>
                                    {slide.description && <p className='hero__text'>{slide.description}</p>}
                                    {/* {slide.link && <Link className='hero__link' to={slide.link}>{t("proposition.detail")}</Link>} */}
                                </div>
                            </div>
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    )
}

export default Hero;