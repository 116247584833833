import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface errorState {
    error: boolean
};

const initialState: errorState = {
    error: false
}

const errorSlice = createSlice({
    name: 'packs',
    initialState,
    reducers: {
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload;
        }
    }
})

export const { setError } = errorSlice.actions;

export default errorSlice.reducer;