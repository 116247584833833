import { FC } from 'react';
import box from '../../assets/img/box.png';
import bagInBox from '../../assets/img/bag-in-box.png';
import canister from '../../assets/img/canister.png';
import tanker from '../../assets/img/tanker.png';
import barel from '../../assets/img/barel.png';
import './Packaging.scss';

interface PackagingProps {

}
const Packaging:FC<PackagingProps> = () => {
    const packages = [
        {id: 1, title: 'Автоцистерна', img: tanker},
        {id: 2, title: 'Каністра', img: canister},
        {id: 3, title: 'Картонна коробка', img: box},
        {id: 4, title: 'Bag in box', img: bagInBox},
        {id: 5, title: 'Металева бочка', img: barel},
    ];

    return (
        <section className="packaging">
            <div className="container">
                <h2 className="packaging__heading">Пакування</h2>
                <p className="packaging__text">Концентрований сік, чай концентований фруктово-ягідний, фруктово — ягідне пюре.</p>
                <ul className="packaging__list">
                    {packages.map(item => (
                        <li key={item.id} className='packaging__item'>
                            <h3 className='packaging__title'>{item.title}</h3>
                            <img className='packaging__img' src={item.img} alt={item.title} />
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default Packaging