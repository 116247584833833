import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { setError } from '../../store/errorSlice';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { API_TRANSLATIONS, API_FEEDBACK } from '../../constants/api';
import LinkBack from '../../components/LinkBack/LinkBack';
import Seo from '../../components/Seo/Seo';
import Spinner from '../../components/Spinner/Spinner';
import './FeedbackPage.scss';
import ErrorBlock from '../../components/ErrorBlock/ErrorBlock';
import postImg from '../../assets/img/msg-post.jpg';

interface FeedbackData {
    name: string;
    email: string;
    message: string;
    phone: string;
}

interface FeedbackInfo {
    title: string;
    questions_remain: string;
    ask_question: string;
    telephone: {
        title: string;
        telephoneNumbers: {
            name: string;
            actualNum: string;
            presentationNum: string;
        }[]
    },
    email: {
        title: string;
        info: string[];
    }
}

const FeedbackPage: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingPost, setLoadingPost] = useState<boolean>(false);
    const [feedbackInfo, setFeedbackInfo] = useState<FeedbackInfo>();
    const [errorPost, setErrorPost] = useState<boolean>(false);
    const [messagePost, setMessagePost] = useState<boolean>(false);
    const [messageNumber, setMessageNumber] = useState<number>(0);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const currentLanguage = useAppSelector(state => state.languages.curentLang);
    const error = useAppSelector(state => state.error.error);
    const {
        register,
        formState: {
            errors,
            isValid
        },
        reset,
        handleSubmit,
    } = useForm<FeedbackData>({
        mode: 'onBlur'
    });

    const getFeedbackInfo = async () => {
        try {
            setLoading(true);
            const res = await axios.get(API_TRANSLATIONS + currentLanguage.id);
            if (res.status === 200) {
                setFeedbackInfo(res.data[0].data.feedback_page);
                dispatch(setError(false));
            }
        } catch (error) {
            dispatch(setError(true));
        } finally {
            setLoading(false);
        }
    }

    const onSubmit = handleSubmit(async (feedbackInfo) => {
        const info = {
            name: feedbackInfo.name,
            email: feedbackInfo.email,
            message: feedbackInfo.message,
            phone: feedbackInfo.phone.length === 0 ? null : feedbackInfo.phone
        }
        try {
            setLoadingPost(true);
            const reqest = await axios.post(API_FEEDBACK, info);
            if (reqest.data.status === "success") {
                setMessagePost(true);
                setMessageNumber(reqest.data.number);
                setErrorPost(false);
                reset();
            }
        } catch (error) {
            setErrorPost(true);
        } finally {
            setLoadingPost(false);
        }
    })

    useEffect(() => {
        window.scrollTo(0, 0);
        getFeedbackInfo();
    }, [currentLanguage])

    return (
        <>
            <Seo
                title="Vitamin2015 «EVA» | Зворотній зв'язок" 
                description = 'Ми цінуємо ваше думку! Надсилайте нам свої враження, питання чи пропозиції через нашу сторінку зворотного зв"язку на сайті "Vitamin2015 Eva". Ваші коментарі важливі для нас, і ми завжди відкриті до співпраці та вдосконалення наших продуктів. Допоможіть нам стати кращими для вас. Разом ми створюємо смачний та здоровий світ з Vitamin2015 Eva.'
            />
            <section className="feedback-page">
                <div className="container">
                    <div className="bread-crumbs">
                        <Link className='bread-crumbs__item' to='/home'>{t("nav.main")}</Link>
                        <span className='bread-crumbs__item'>{t("nav.feedback")}</span>
                    </div>
                    <LinkBack />
                    {loading ? <Spinner /> : (
                        error ? <ErrorBlock /> : (
                            <>
                                <div className='feedback-page__banner'>
                                    <div className='feedback-page__content'>
                                        <h1 className="feedback-page__title">{feedbackInfo?.title}</h1>
                                        <p className="feedback-page__text">{feedbackInfo?.questions_remain}</p>
                                    </div>
                                    <div className="feedback-page__overlay" />
                                </div>

                                <h2 className='feedback-page__subtitle'>{feedbackInfo?.telephone.title}</h2>
                                <ul className='feedback-page__list'>
                                    {feedbackInfo?.telephone.telephoneNumbers.map(item => (
                                        <li key={item.actualNum} className='feedback-page__item'>
                                            <a href={`tel:${item.actualNum}`}>{item.presentationNum} - {item.name}</a>
                                        </li>
                                    ))}
                                </ul>
                                <h2 className='feedback-page__subtitle'>{feedbackInfo?.email.title}</h2>
                                <ul className='feedback-page__list'>
                                    {feedbackInfo?.email.info.map(item => (
                                        <li key={item} className='feedback-page__item'>
                                            <a href={`mailto:${item}`}>{item}</a>
                                        </li>
                                    ))}
                                </ul>
                                {messagePost ? (
                                    <div className='feedback-page__modal'>
                                        <img src={postImg} alt='Повідомлення доставлено' className='feedback-page__modal-img' />
                                        <h3 className='feedback-page__modal-title'>
                                            Дякуємо за звернення, повідомлення успішно доставлено.
                                        </h3>
                                        <p className='feedback-page__modal-text'>Зв'яжемося з Вами  найближчим часом <br /> Номер вашого звернення № {messageNumber}</p>
                                        <button 
                                            onClick={() => setMessagePost(false)} 
                                            className='order-form__order-btn'
                                        >
                                            Зв'язатись знову
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <p className="feedback-page__text">{feedbackInfo?.ask_question}</p>

                                        <form className='order-form' onSubmit={onSubmit}>
                                            <div className="order-form__container">
                                                <div className='order-form__field'>
                                                    <label htmlFor='firstname'>{t("order_page.order_form.name")}</label>
                                                    <input
                                                        type="text"
                                                        id='firstname'
                                                        className='order-form__input-text'
                                                        {...register('name', {
                                                            required: t("order_page.order_form.required_field") || 'required',
                                                            minLength: {
                                                                value: 3,
                                                                message: t("order_page.order_form.min_symbols") || ''
                                                            },
                                                            maxLength: {
                                                                value: 40,
                                                                message: t("order_page.order_form.max_symbols") || ''
                                                            }
                                                        })}
                                                    />
                                                    <div className='order-form__error'>
                                                        {errors.name && <p>{errors.name.message || 'Error'}</p>}
                                                    </div>
                                                </div>
                                                <div className='order-form__field'>
                                                    <label htmlFor='email'>{t("order_page.order_form.email")}</label>
                                                    <input
                                                        type="text"
                                                        id='email'
                                                        className='order-form__input-text'
                                                        {...register('email', {
                                                            required: t("order_page.order_form.required_field") || 'required',
                                                            pattern: {
                                                                value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                message: t("order_page.order_form.wrong_email") || 'Incorect email'
                                                            }
                                                        })}
                                                    />
                                                    <div className='order-form__error'>
                                                        {errors.email && <p>{errors.email.message || 'Error'}</p>}
                                                    </div>
                                                </div>
                                                <div className='order-form__field'>
                                                    <label htmlFor='phoneNumber'>{t("order_page.order_form.phone")}</label>
                                                    <input
                                                        type="tel"
                                                        id='phoneNumber'
                                                        autoComplete='on'
                                                        className='order-form__input-text'
                                                        placeholder='+380*********'
                                                        {...register('phone', {
                                                            pattern: {
                                                                value: /^[+]{0,1}380([0-9]{9})$/,
                                                                message: t("order_page.order_form.wrong_phone") || '+380*********'
                                                            }
                                                        })}
                                                    />
                                                    <div className='order-form__error'>
                                                        {errors.phone && <p>{errors.phone.message || 'Error'}</p>}
                                                    </div>
                                                </div>
                                                <div className="order-form__field">
                                                    <label htmlFor='message'>{t("order_page.order_form.message")}</label>
                                                    <textarea
                                                        id='message'
                                                        className='order-form__input-text feedback-page__text-area'
                                                        maxLength={600}
                                                        {...register('message', {
                                                            required: t("order_page.order_form.required_field") || 'required',
                                                            minLength: {
                                                                value: 3,
                                                                message: t("order_page.order_form.min_symbols") || ''
                                                            }
                                                        })}
                                                    />
                                                    <div className='order-form__error'>
                                                        {errors.message && <p>{errors.message.message || 'Error'}</p>}
                                                    </div>
                                                </div>
                                                <button className='order-form__order-btn' type='submit'>{t("order_page.order_form.send")}</button>
                                                {errorPost && (
                                                    <p style={{ marginTop: '10px' }} className='order-form__fail'>Щось пішло не так, спробуйте пізніше</p>
                                                )}
                                                {loadingPost && <Spinner />}
                                            </div>
                                        </form>
                                    </>
                                )}
                            </>
                        )
                    )}
                </div>
            </section>
        </>
    )
}

export default FeedbackPage