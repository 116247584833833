import { configureStore } from '@reduxjs/toolkit';

import cartReducer from './cartSlice';
import recentlyViewedReducer from './recentViewSlice';
import languageReducer from './languageSlice';
import packReducer from './packsSlice';
import errorReducer from './errorSlice';

const store = configureStore({
  reducer: {
    cartItems: cartReducer,
    recentlyViewed: recentlyViewedReducer,
    languages: languageReducer,
    packs: packReducer,
    error: errorReducer
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;