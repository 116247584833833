import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPackType, IPackUnit } from '../types/types';

interface PackState {
    packType: IPackType[];
    packUnit: IPackUnit[]
};

const initialState: PackState = {
    packType: [],
    packUnit: []
}

const packSlice = createSlice({
    name: 'packs',
    initialState,
    reducers: {
        setPackType: (state, action: PayloadAction<IPackType[]>) => {
            state.packType = action.payload;
        },
        setPackUnit: (state, action: PayloadAction<IPackUnit[]>) => {
            state.packUnit = action.payload;
        }
    }
})

export const { setPackType, setPackUnit } = packSlice.actions;

export default packSlice.reducer;