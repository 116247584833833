import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { API_TRANSLATIONS } from './constants/api';
import { ITranslation, StaticTranslation } from './types/types';
import axios from 'axios';
// import translationUa from './locales/UA/translation.json';
// console.log(translationUa)

const resources = {
  '1': {
    translation: {
      "nav": {
        "main": "Асортимент",
        "categories": "Категорії",
        "about": "Про нас",
        "contacts": "Контакти",
        "feedback": "Зворотній зв'язок",
        "info": "Інформація",
        "search": "Пошук",
        "back": "Назад",
        "to_catalog": "До каталогу",
        "to_main": "На головну",
        "to_store": "До магазину",
        "to_faq": "Питання та відповіді"
    },
    "proposition": {
        "promo": "Акції",
        "new_products": "Нові продукти",
        "recently_viewed": "Нещодавно переглянуті",
        "detail": "Детальніше"
    },
    "contact_page": {
        "titleCompany": {
          "name": "Назва компанії",
          "info": "ТОВ 'Вітамін2015'"
        },
        "address": {
          "name": "Адреса",
          "info": [
            "с. Лепесівка, вул. Залізнодорожна 14, Хмельницька обл."
          ]
        },
        "email": {
          "name": "Email",
          "info": [
            "vitamin.rsv@gmail.com"
          ]
        },
        "telephone": {
          "name": "Телефон",
          "telephoneNumber": [
            {
              "presentationNum": "+38 (096) 198-98-06",
              "actualNum": "+380961989806"
            },
            {
              "presentationNum": "+38 (096) 085-39-34",
              "actualNum": "+380960853934"
            },
            {
              "presentationNum": "+38 (068) 626-32-26",
              "actualNum": "+380686263226"
            },
            {
              "presentationNum": "+38 (067) 199-01-09",
              "actualNum": "+380671990109"
            }
          ]
        },
        "schedule": {
          "name": "Графік роботи",
          "time": "Пн - Пт 09:00 - 18:00; Сб, Нд - Вихідні"
        },
        "location": {
          "name": "Місцезнаходження",
          "googleMap": "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2547.3825111721117!2d26.879228015542644!3d50.322114504485064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTDCsDE5JzE5LjYiTiAyNsKwNTInNTMuMSJF!5e0!3m2!1sru!2sua!4v1681031957258!5m2!1sru!2sua"
        }
    },
    "search_page": {
        "title": "Пошук",
        "search_by_request": "Пошук по запиту:",
        "placeholder": "Пошук: введіть артикул або назву товару",
        "nothing_found": "Нічого не знайдено",
        "found": "Всього знайдено",
        "products": "товарів",
        "view_all": "Переглянути всі"
    },
    "about_page": {
        "about_company": "Про компанію",
        "our_company": "Наша компанія",
        "benefits": "Переваги",
        "production_capacity": "Виробнича потужність",
        "trust": "Нам довіряють",
        "awards": "Нагороди",
        "gallery": "Галерея",
        "faq": "Питання, які нам часто задають"
    }, 
    "sorting": {
        "title": "Сортування",
        "price_down": "По ціні (від меншої до більшої)",
        "price_up": "По ціні (від більшої до меншої)",
        "title_down": "По назві (А-Я)",
        "title_up": "По назві (Я-А)",
        "date_down": "По даті (Спочатку старі)",
        "date_up": "По даті (Спочатку нові)"
    },
    "buy_info": {
        "price": "Ціна:",
        "uah": "грн",
        "in_stock": "Є в наявності",
        "out_of_stock": "Немає в наявності",
        "barcode": "Артикул:",
        "min_count_buy": "* мінімальна кількість замовлення -",
        "add_to_cart": "Додати в корзину",
        "in_cart": "Товар в корзині",
        "sum": "Разом: ",
        "added_to_cart": "додано до кошика" 
    },
    "product_page": {
        "characteristics": "Характеристики",
        "description": "Опис"
    },
    "cart": {
        "cart": "Кошик",
        "clear_cart": "Очистити кошик",
        "empty_cart": "Кошик порожній",
        "empty_cart_descr": "На даний момент кошик пустий. Щоб зробити замовлення перейдіть до каталогу товарів та додайте щось в кошик",
        "close_cart": "Закрити корзину",
        "order": "Оформити замовлення"
    },
    "order_page": {
        "order": "Ваше замовлення",
        "order_complete": "Замовлення оформлено",
        "order_complete_descr": "Замовлення успішно оформлено. Чекайте поки наш менеджер зв'яжеться з вами для підтвердження замовлення",
        "order_pick_up": "Забрати замовлення можна по нашій адресі:",
        "our_address": "вулиця Привокзальна, 9, Славута, Хмельницька область, 30000",
        "our_address_short": "Хмельницька область, вулиця Привокзальна 9",
        "order_form": {
            "title": "Інформація про вас",
            "name": "Ім'я:",
            "surname": "Прізвище:",
            "by_father": "По батькові:",
            "email": "Email:",
            "wrong_email": "Email адресу введено невірно",
            "phone": "Номер телефону:",
            "wrong_phone": "Формат вводу: +380*********",
            "delivery": "Вибір доставки:",
            "city": "Місто:",
            "region": "область",
            "branch": "Відділення:",
            "branch_num": "відділення #",
            "required_field": "Поле обов'язкове до заповнення",
            "min_symbols": "Мінімально 3 символа",
            "max_symbols": "Максимально 15 символів",
            "nova_poshta": "Нова пошта",
            "meest_express": "Meest Express",
            "self_deliver": "Самовивіз"
        }
    }
    }
  },
  '2': {
    translation: {
      "nav": {
        "main": "Main",
        "categories": "Categories",
        "about": "About us",
        "contacts": "Contacts",
        "feedback": "Feedback",
        "info": "Information",
        "search": "Search",
        "back": "Back",
        "to_catalog": "To catalog",
        "to_main": "To the main",
        "to_store": "To the store",
        "to_faq": "To FAQ"
    },
    "proposition": {
        "promo": "Promo",
        "new_products": "New Products",
        "recently_viewed": "Recently Viewed",
        "detail": "More details"
    },
    "contact_page": {
        "titleCompany": {
          "name": "The company name",
          "info": "LLC 'Vitamin2015'"
        },
        "address": {
          "name": "Address",
          "info": [
            "Lepesivka Vill, st. Zaliznodorozhna 14, Khmelnytska region."
          ]
        },
        "email": {
          "name": "Email",
          "info": [
            "vitamin.rsv@gmail.com"
          ]
        },
        "telephone": {
          "name": "Phone",
          "telephoneNumber": [
            {
              "presentationNum": "+38 (096) 198-98-06",
              "actualNum": "+380961989806"
            },
            {
              "presentationNum": "+38 (096) 085-39-34",
              "actualNum": "+380960853934"
            },
            {
              "presentationNum": "+38 (068) 626-32-26",
              "actualNum": "+380686263226"
            },
            {
              "presentationNum": "+38 (067) 199-01-09",
              "actualNum": "+380671990109"
            }
          ]
        },
        "schedule": {
          "name": "Work schedule",
          "time": "Mon - Fri 09:00 - 18:00; Sat, Sun - Weekends (Kyiv time)"
        },
        "location": {
          "name": "Location",
          "googleMap": "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2547.3825111721117!2d26.879228015542644!3d50.322114504485064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTDCsDE5JzE5LjYiTiAyNsKwNTInNTMuMSJF!5e0!3m2!1sru!2sua!4v1681031957258!5m2!1sru!2sua"
        }
    },
    "search_page": {
        "title": "Search",
        "search_by_request": "Search by request:",
        "placeholder": "Search: enter article number or product name",
        "nothing_found": "Nothing found",
        "found": "Found in total",
        "products": "products",
        "view_all": "View all"
    },
    "about_page": {
        "about_company": "About company",
        "our_company": "Our company",
        "benefits": "Benefits",
        "production_capacity": "Production capacity",
        "trust": "They trust us",
        "awards": "Awards",
        "gallery": "Gallery",
        "faq": "Questions we are often asked"
    },
    "sorting": {
        "title": "Sorting",
        "price_down": "By price (low to high)",
        "price_up": "By price (high to low)",
        "title_down": "By name (A-Z)",
        "title_up": "By name (Z-A)",
        "date_down": "By date (Old first)",
        "date_up": "By date (New first)"
    },
    "buy_info": {
        "price": "Price:",
        "uah": "uah",
        "in_stock": "In stock",
        "out_of_stock": "Out of stock",
        "barcode": "Barcode:",
        "min_count_buy": "* minimum order quantity -",
        "add_to_cart": "Add to cart",
        "in_cart": "Product in the cart",
        "sum": "Sum:",
        "added_to_cart": "added to your cart" 
    },
    "product_page": {
        "characteristics": "Characteristics",
        "description": "Description"
    },
    "cart": {
        "cart": "Cart",
        "clear_cart": "Clear cart",
        "empty_cart": "Cart is empty",
        "empty_cart_descr": "Your cart is currently empty. To place an order, go to the product catalog and add something to the cart",
        "close_cart": "Close cart",
        "order": "Place an order"
    },
    "order_page": {
        "order": "Your order",
        "order_complete": "The order has been placed",
        "order_complete_descr": "The order has been successfully placed. Wait for our manager to contact you to confirm the order",
        "order_pick_up": "You can pick up your order at our address:",
        "our_address": "st. Pryvokzalna 9, Slavuta, Khmelnytsky region, 30000",
        "our_address_short": "Khmelnytsky region, Privokzalnaya Street 9",
        "order_form": {
            "title": "Information about you",
            "name": "Name:",
            "surname": "Surname:",
            "by_father": "By father:",
            "email": "Email:",
            "wrong_email": "Email address entered incorrectly",
            "phone": "Phone number:",
            "wrong_phone": "Input format: +380*********",
            "delivery": "Choosing a delivery:",
            "city": "City:",
            "region": "region",
            "branch": "Branch:",
            "branch_num": "branch #",
            "required_field": "This field is required",
            "min_symbols": "Minimum 3 characters",
            "max_symbols": "Maximum 15 characters",
            "nova_poshta": "Nova Poshta",
            "meest_express": "Meest Express",
            "self_deliver": "Self deliver"
        }
    }
    }
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "1",
    lng: "1",
    interpolation: {
      escapeValue: false
    }
  })


const getTranslations = async () => {
  try {
    const res = await axios.get<ITranslation[]>(API_TRANSLATIONS + '*');

    const resources: Record<string, { translation: StaticTranslation }> = {};

    res.data.forEach((item) => {
      resources[String(item.lang_id)] = { translation: item.data }
    })

    i18n.init({
      resources,
      fallbackLng: '1',
      lng: '1',
      interpolation: {
        escapeValue: false,
      },
    });

  } catch (error) {
    console.log('Error ocurred, try another time');
  }
}
getTranslations();

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "1",
    lng: "1",
    interpolation: {
      escapeValue: false
    }
  })

export default i18n;