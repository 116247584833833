import { FC } from 'react';
import { useInView } from 'react-intersection-observer';
import './History.scss';
import apple from '../../assets/img/apple.svg';

interface Info {
    year: number;
    text: string
}

interface HistoryProps {
    title: string
    sections: Info[];
}

const History:FC<HistoryProps> = ({ title, sections }) => {
    const innerWidth = window.innerWidth;
    const [ref, inView] = useInView({threshold: innerWidth < 900 ? 0.1 : 0.3, triggerOnce: true});
    return (
        <section className="history" ref={ref}>
            <div className="container">
                <div className="history__inner">
                    <h2 className={inView ? 'gallery__title gallery__title--active' : 'gallery__title'}>{title}</h2>
                    <ul className={inView ? 'history__list history__list--active' : 'history__list'}>
                        {sections.map((item, i) => (
                            <li key={item.year} className={`history__item history__item--${i % 2 === 0 ? 'short' : 'long'}`}>
                                <div className="history__container">
                                    <img className='history__apple' src={apple} alt='apple' />
                                    <p className='history__year'>{item.year}</p>
                                </div>
                                <span className={`history__line history__line--${i % 2 === 0 ? 'short' : 'long'}`} />
                                <p className={`history__text history__text--${i % 2 === 0 ? 'short' : 'long'}`}>{item.text}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default History;