import { useState, Suspense, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../hooks/reduxHooks";
import { setPackType, setPackUnit } from "../../store/packsSlice";
import { BASE_API } from "../../constants/api";
import axios from "axios";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Spinner from "../../components/Spinner/Spinner";
import ScrollTopBtn from "../../components/ScrollTopBtn/ScrollTopBtn";

import HomePage from "../HomePage/HomePage";
import ProductPage from "../ProductPage/ProductPage";
import CatalogPage from "../CatalogPage/CatalogPage";
import CategoryPage from "../CategoriesPages/CategoryPage";
import OrderPage from '../OrderPage/OrderPage';
import AboutPage from "../AboutPage/AboutPage";
import ContactPage from "../ContactPage/ContactPage";
import SearchPage from "../SearchPage/SearchPage";
import FeedbackPage from "../FeedbackPage/FeedbackPage";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

import { IProductDetail } from "../../types/types";

function App() {
  const dispatch = useAppDispatch();
  const [cartOpen, setCartOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchList, setSearchList] = useState<IProductDetail[]>([]);
  const currentLanguage = useAppSelector(state => state.languages.curentLang);

  const getPackTypes = async () => {
    try {
      const res = await axios.get(`${BASE_API}/packs/types?lang_id=${currentLanguage.id}`);
      if (res.status === 200) {
        dispatch(setPackType(res.data));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getPackUnits = async () => {
    try {
      const res = await axios.get(`${BASE_API}/packs/units?lang_id=${currentLanguage.id}`);
      if (res.status === 200) {
        dispatch(setPackUnit(res.data));
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getPackTypes();
    getPackUnits();
  }, [currentLanguage])

  return (
    <>
      <Suspense fallback={<Spinner />}>
        <div className="wrapper">
          <Header
            cartOpen={cartOpen}
            setCartOpen={setCartOpen}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            searchList={searchList}
            setSearchList={setSearchList}
          />
          <main>
            <Routes>
              <Route path="/" element={<AboutPage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path='/categories' element={<CatalogPage />} />
              <Route path="/product/:id" element={<ProductPage setCartOpen={setCartOpen} />} />
              <Route path="/categories/:id" element={<CategoryPage />} />
              <Route path="/order" element={<OrderPage />} />
              <Route path="/contacts" element={<ContactPage />} />
              <Route path="/feedback" element={<FeedbackPage />} />
              <Route path="/search" element={
                <SearchPage
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  searchList={searchList}
                  setSearchList={setSearchList}
                />
              } />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <ScrollTopBtn />
          </main>
          <Footer />
        </div>
      </Suspense>
    </>
  )
}

export default App;