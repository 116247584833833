import { FC } from 'react';
import { useInView } from 'react-intersection-observer';
import './Partners.scss';

interface PartnersProps {
    title: string;
    photos: string[];
}

const Partners:FC<PartnersProps> = ({title, photos}) => {
    const innerWidth = window.innerWidth;
    const [ref, inView] = useInView({threshold: innerWidth < 900 ? 0.1 : 0.2, triggerOnce: true});
    return (
        <section className='partners' ref={ref}>
            <div className="container">
                <h2 className={inView ? "partners__title  partners__title--active" : "partners__title "}>{title}</h2>
                <ul className={inView ? "partners__list partners__list--active" : 'partners__list'}>
                    {photos.map(photo => (
                        <li key={photo}>
                            <img className='partners__photo' src={photo} alt='partner' />
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default Partners