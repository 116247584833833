import { useState, useEffect } from 'react';
import { useAppSelector } from '../../hooks/reduxHooks';
import { API_NEW_PRODUCTS, API_PROMOTIONS, API_BANNERS } from '../../constants/api';
import { IProductDetail, IBanner } from '../../types/types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import Seo from '../../components/Seo/Seo';
import Hero from '../../components/Hero/Hero';
import Categories from '../../components/Categories/Categories';
import Proposition from '../../components/Proposition/Proposition';
import RecentlyViewed from '../../components/RecentlyViewed/RecentlyViewed';
import Packaging from '../../components/Packaging/Packaging';
import './HomePage.scss';

const HomePage = () => {
    const [banners, setBanners] = useState<IBanner[]>([]);
    const [newProducts, setNewProducts] = useState<IProductDetail[]>();
    const [promotions, setPromotions] = useState<IProductDetail[]>();
    const currentLanguage = useAppSelector(state => state.languages.curentLang);
    const { t } = useTranslation();

    const getBanners = async () => {
        try {
            const res = await axios.get(API_BANNERS + `?lang_id=${currentLanguage.id}`);
            if(res.status === 200) {
                setBanners(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getProducts = async (link: string, type: string) => {
        try {
            const res = await axios.get(link + `?lang_id=${currentLanguage.id}&page_size=8`);
            if(res.status === 200) {
                switch(type) {
                    case 'newProducts': setNewProducts(res.data.products); break
                    case 'promotions': setPromotions(res.data.products); break
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getBanners();
        getProducts(API_NEW_PRODUCTS, 'newProducts');
        getProducts(API_PROMOTIONS, 'promotions');
    }, [currentLanguage])
    return (
        <>
            <Seo 
                title="Vitamin2015 «EVA» | Асортимент" 
                description = 'Ласкаво просимо на сайт Vitamin2015 «EVA» – вашого вірного партнера у світі смаку та здорового харчування. Відкрийте для себе наш широкий асортимент натуральних соків, зброжених соків та фруктових-овочевих сиропів. Насолоджуйтеся багатством смаку у кожній краплі – від свіжих фруктових експлозій до освіжаючих концентрованих чаїв. Наша спеціальність – неосвітлені соки, які зберігають природні властивості та вітаміни.  Поглибіться в світ здорового харчування та смакових вражень з Вітамін2015 «EVA»!'
            />
            <Hero banners={banners} /> 
            <Categories />
            {promotions && (
               <Proposition 
                    title={t("proposition.promotions")}
                    products={promotions}
                    type='promo'
                /> 
            )}

            {newProducts && (
                <Proposition 
                    title={t("proposition.new_products")}
                    products={newProducts}
                />
            )}

            <RecentlyViewed type='main' />
            <Packaging />
        </>
    )
}

export default HomePage;