export const BASE_API = process.env.REACT_APP_API_BASE;

export const API_CATEGORIES = BASE_API + '/list/categories';
export const API_CATEGORIES_PRODUCTS = BASE_API + '/categories';
export const API_PRODUCT = BASE_API + '/products?id=';
export const API_PRODUCTS = BASE_API + '/products/list?ids=';
export const API_NEW_PRODUCTS = BASE_API + '/categories/new_products';
export const API_PROMOTIONS = BASE_API + '/categories/promotions';
export const API_SEARCH = BASE_API + '/products/search=';
export const API_SEARCH_CATEGORY = BASE_API + '/categories/search?value=';
export const API_LANGS = BASE_API + '/list/langs';
export const API_TRANSLATIONS = BASE_API + '/list/site-translations?lang_ids=';
export const API_DELIVERS = BASE_API +'/deliveries';
export const API_ORDERS = BASE_API +'/orders';
export const API_FEEDBACK = BASE_API + '/feedback';
export const API_BANNERS = BASE_API + '/list/banners';

export const MEEST_BASE = 'https://publicapi.meest.com';
export const MEEST_SEARCH_CITY = MEEST_BASE + '/geo_localities?search_beginning=';
export const MEEST_SEARCH_BRANCHES = MEEST_BASE + '/locator/';

export const NOVA_POST_BASE = 'https://api.novaposhta.ua/v2.0/json/';
export const NOVA_POST_KEY = process.env.REACT_APP_NOVA_POST_KEY;