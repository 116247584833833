import { FC } from 'react';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/img/logo.svg';
import newLogo from '../../assets/img/new-logo.png';
import logoHeader from '../../assets/img/logo_header.png';
import logoFooter from '../../assets/img/logo_footer.png';
import './Logo.scss';

interface LogoProps {
    type: string;
    classes?: string;
}

const Logo:FC<LogoProps> = ({ type }) => {
    const { t } = useTranslation();
    return (
        <Link to ='/home' className={type === 'footer' ? 'logo logo--footer' : 'logo logo--header'}>
            <img 
                className={type === 'footer' ? 'logo__img--footer' : 'logo__img logo__img--header'} 
                src={type === 'header' ? logoHeader : logoFooter} 
                alt='Eva' 
            />
            {type === 'header' && (
                <span className='logo__text'>{t("contact_page.titleCompany.info")}</span>
            )}
        </Link>
    )
}

export default memo(Logo);