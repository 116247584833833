import errorImage from '../../assets/img/error.jpg';
import './ErrorBlock.scss';

const ErrorBlock = () => {
    return (
        <div className="error-block">
            <div>
                <img className='error-block__img' src={errorImage} alt='error' />
                <h2 className="error-block__title">Щось пішло не так</h2>
                <p className="error-block__text">Перезавантажте сторінку або спробуйте пізніше</p>
            </div>
        </div>
    )
}

export default ErrorBlock