import { IPackType, IPackUnit } from "../types/types";

export const unitChange = (id: number, units: IPackUnit[]) => {
    const a = units.find(unit => unit.id === id);
    if(a) {
        return a.short_name
    } else {
        return 'л'
    }
}

export const packTypeChange = (id: number, types: IPackType[]) => {
    const a = types.find(type => type.id === id);
    if(a) {
        return a.name
    } else {
        return 'Розмір'
    }
}